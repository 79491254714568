import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import toast from 'react-hot-toast';

// Loader Components
import showLoader from '@components/loader';

export const getCustomerProfiles = createAsyncThunk('customerProfile/getCustomerProfiles', async () => {
    try {
        let response = await axios.get('/customer-profile', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomerProfile = createAsyncThunk('customerProfile/createCustomerProfile', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/customer-profile', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document added successfully.');
        showLoader(false);
        dispatch(getCustomerProfiles());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerProfile = createAsyncThunk('customerProfile/updateCustomerProfile', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/customer-profile/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document updated succesfully.');
        showLoader(false);
        dispatch(getCustomerProfiles());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCustomerProfile = createAsyncThunk('customerProfile/deleteCustomerProfile', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/customer-profile/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer Profile Deleted Successfully.');

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatDefaultProfile = createAsyncThunk('customerProfile/updatDefaultProfile', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/customer-profile/is-default/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getCustomerProfiles());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const customerProfile = createSlice({
    name: 'customerProfile',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getCustomerProfiles.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default customerProfile.reducer;
